import React from 'react';
import WpRow from '../components/WordPress/WpGrid/WpRow';
import WpColumn from '../components/WordPress/WpGrid/WpColumn';
import WpRow11 from '../components/WordPress/WpGrid/WpRow_1_1';
import WpRow21 from '../components/WordPress/WpGrid/WpRow_2_1';
import WpRow12 from '../components/WordPress/WpGrid/WpRow_1_2';

const reactComponents = {
	row: WpRow,
	row_1_1: WpRow11,
	row_2_1: WpRow21,
	row_1_2: WpRow12,
	column_1: WpColumn,
	column_2: WpColumn,
};

export const renderComponents = (acfComponents, rowLength) => {
	const components = acfComponents;
	const componentsObject = {};
	for (let i = 1; i <= rowLength; i += 1) {
		const filteredEntries = components.filter((c) => {
			return c.belongsToRow === i;
		});
		if (filteredEntries && filteredEntries.length > 0) {
			const rowType = filteredEntries[0].layout;
			componentsObject[i] = {
				rowType,
				elements: filteredEntries,
			};
		}
	}
	return Object.keys(componentsObject).map((key) => {
		const c = componentsObject[key];
		const WpComponent = reactComponents[c.rowType];
		// console.log('WpComponent: ', WpComponent);
		if (!WpComponent) {
			return null;
		}
		return <WpComponent key={key} content={c} />;
	});
};

import styled, {css, keyframes} from 'styled-components';
import Link from '../../Elements/Link/Link';
import {colors, sizes} from '../../../styles/variables';
import {media, withUnit} from '../../../styles/utils';
import {Icon} from '../../Elements/Icon/Icon';
import {HeadlineStyledH4} from '../../Elements/Headline/styles';

const rotate = keyframes`
  from {
  	opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const FadeIn = css`
	opacity: 0;
	animation: ${rotate} 0.4s ease 0.1s forwards;
`;
const ItemsLevel2Styles = css`
	padding-bottom: 0.8em;
`;

export const SidebarItemsStyled = styled.ul`
	${(props) => (props.level === 2 ? ItemsLevel2Styles : '')};
	:empty {
		padding-bottom: 0em;
	}
`;

const ItemLevel1Styles = css`
	border-bottom: 2px solid ${colors.snow};
`;

export const SidebarItemStyled = styled.li`
	${(props) => (props.level === 1 ? ItemLevel1Styles : '')};
`;

const LinkLevel1Styles = css`
	font-size: 0.8em;
	font-weight: 600;
	padding: 0.8em 0;
`;

const LinkLevel2Styles = css`
	font-size: 0.7em;
	padding: 0.4em 0 0.4em 1em;
`;

export const IconStyled = styled(Icon)``;

export const SidebarItemLink = styled(Link)`
	display: flex;
	justify-content: space-between;
	align-items: center;
	color: ${colors.black};
	transition: color 0.3s ease;
	${(props) => (props.active ? `color: ${colors.carrot}; font-weight: 700;` : '')};
	${(props) => (props.level === 1 ? LinkLevel1Styles : LinkLevel2Styles)};

	${() => IconStyled} {
		${(props) => (props.active ? `color: ${colors.carrot};` : '')};
	}

	&:hover,
	&:active,
	&:focus {
		color: ${colors.carrot};
	}
`;

export const SidebarStyled = styled.div`
	display: none;

	${media.lg`
		display: block;
    	margin-right: ${withUnit(sizes.distance.base)}
	`};
`;

export const HeadlineStyled = styled(HeadlineStyledH4)`
	font-weight: 800;
	color: ${colors.black};
`;

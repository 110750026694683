/* eslint-disable no-restricted-globals */
import React from 'react';
import PropTypes from 'prop-types';
import {navigate} from '@reach/router';
import {connect} from 'react-redux';
import DynamicPage from '../../components/Container/DynamicPage/DynamicPage';
import Section from '../../components/Layout/Section/Section';
import Row from '../../components/Layout/Grid/Row';
import Column from '../../components/Layout/Grid/Column';
import Headline from '../../components/Elements/Headline/Headline';
import {PageTemplateStyled} from '../styles';
import Teaser from '../../components/Elements/Teaser/Teaser';
import {HeadlineStyledH3} from '../../components/Elements/Headline/styles';
import Meta, {appendSeoTitleSuffix} from '../../components/Layout/Meta';
import ReleaseNumber from '../../components/Elements/ReleaseNumber/ReleaseNumber';
import ContactSection from '../../components/Content/ContactSection/ContactSection';
import {content} from '../../assets/language/de';
import LoginReminder from '../../components/Container/Login/LoginReminder/LoginReminder';

const PageTemplateDynamic = (props) => {
	const {title, page, seoDesc, user, pzn} = props;

	let slug = location.pathname.split('/');
	slug = slug.filter((el) => {
		return el !== null && el !== '';
	});

	const isPznSpecial = slug.includes('international');

	const isPznAR = location.pathname.includes('/ar/');

	let key = 'XYZ';

	if (pzn) {
		key = pzn.slug;
	}

	const currentPathname = page.location.pathname;

	if (isPznSpecial && !currentPathname.includes(key)) {
		navigate('/spezial/international');
		return null;
	}
	if (isPznAR && !currentPathname.includes(key)) {
		navigate('/service/ms-behandlung');
		return null;
	}

	const beforeParse = title;

	let afterParse = null;

	const regex1 = /<</gi;
	const regex2 = />>/gi;

	afterParse = beforeParse.replace(regex1, '');
	afterParse = afterParse.replace(regex2, '');

	return (
		<>
			<Meta title={appendSeoTitleSuffix(afterParse)} description={seoDesc} />
			<PageTemplateStyled>
				<Section showPadding>
					<Row>
						<Column>
							<Section>
								<div className="category" style={{display: 'none'}}>
									{location.pathname.split('/')[1]}
								</div>
								<>
									<Headline itemScope as="h1" text={afterParse} center />
									{currentPathname.includes(key) || (user && !isPznSpecial) ? (
										<DynamicPage page={page} {...props} />
									) : (
										<LoginReminder />
									)}
								</>
							</Section>
						</Column>
					</Row>
				</Section>
				<Section>
					<ContactSection />
				</Section>
			</PageTemplateStyled>
		</>
	);
};

export const PageTemplate = (props) => {
	const {title, components, releaseNumber, relatedContent, seoDesc} = props;

	let relatedContentRow = null;
	let relatedContentSection = null;

	if (relatedContent && relatedContent.length > 0) {
		relatedContentRow = relatedContent.map((relatedPost, index) => {
			const teaserItem = {
				appearance: 'relatedContent',
				title: relatedPost.title,
				label: '',
				link: {
					url: `/${relatedPost.slug}`,
					title: relatedPost.title,
					target: '_self',
				},
				image: {
					source_url: '',
					alt_text: relatedPost.title,
					title: relatedPost.title,
					localFile: {
						publicURL: relatedPost.thumbnail,
					},
				},
			};

			return (
				<Column key={relatedPost.id} lg={4}>
					<Teaser position={index + 1} item={teaserItem} />
				</Column>
			);
		});

		relatedContentSection = (
			<Section showPadding theme="grey">
				<Row>
					<Column>
						<HeadlineStyledH3>{content.relatedContent.headline}</HeadlineStyledH3>
					</Column>
				</Row>
				<Row>{relatedContentRow}</Row>
			</Section>
		);
	}

	return (
		<>
			<Meta title={appendSeoTitleSuffix(title)} description={seoDesc} />
			<PageTemplateStyled>
				<Section showPadding>
					<Row>
						<Column>
							<Section>
								<Headline center as="h1" text={title} />
								{components}
								<ReleaseNumber releaseNumber={releaseNumber} />
							</Section>
						</Column>
					</Row>
				</Section>
				<Section>
					<ContactSection />
					{relatedContentSection}
				</Section>
			</PageTemplateStyled>
		</>
	);
};

const mapStateToProps = (state) => ({
	user: state.userManagement.user,
	pzn: state.userManagement.pzn,
});

PageTemplate.propTypes = {
	title: PropTypes.string.isRequired,
	components: PropTypes.oneOfType([PropTypes.array, PropTypes.element]),
	releaseNumber: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	relatedContent: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.number,
			title: PropTypes.string,
			slug: PropTypes.string,
		})
	),
	seoDesc: PropTypes.string,
};

PageTemplate.defaultProps = {
	relatedContent: [],
	components: null,
	releaseNumber: null,
	seoDesc: null,
};

PageTemplateDynamic.propTypes = {
	user: PropTypes.shape({}),
	page: PropTypes.shape({
		location: PropTypes.shape({
			pathname: PropTypes.string,
		}),
	}),
	pzn: PropTypes.shape({
		slug: PropTypes.string,
		name: PropTypes.string,
	}),
	title: PropTypes.string.isRequired,
	components: PropTypes.oneOfType([PropTypes.array, PropTypes.element]),
	releaseNumber: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	relatedContent: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.number,
			title: PropTypes.string,
			slug: PropTypes.string,
		})
	),
	seoDesc: PropTypes.string,
};

PageTemplateDynamic.defaultProps = {
	user: null,
	pzn: null,
	page: null,
	relatedContent: [],
	components: null,
	releaseNumber: null,
	seoDesc: null,
};

export default connect(mapStateToProps)(PageTemplateDynamic);

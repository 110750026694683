import React, {useEffect, useState} from 'react';
import {navigate} from 'gatsby';
import {useLocation} from '@reach/router';

import {Formik, Form} from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {FormFieldContainer, ButtonStyled, LinkStyled} from './styles';

import {Input} from '../../Elements/Form/InputAndTextarea/InputAndTextarea';
import {PasswordInput} from '../../Elements/Form/InputAndTextarea/PasswordInput';
import {userLogin} from '../../../state/actions/userManagement';
import Column from '../../Layout/Grid/Column';
import FormRow from '../../Elements/Form/FormRow/FormRow';
import Section from '../../Layout/Section/Section';
import Spinner from '../../Elements/Spinner';

import {usePrevPath} from '../../../utils/hooks/usePrevPath';

const LoginSchema = Yup.object().shape({
	username: Yup.string().required('Bitte gib deinen Benutzernamen ein.'),
	password: Yup.string().required('Bitte gib dein Passwort ein.'),
});

const Login = (props) => {
	const {user, type} = props;
	const location = useLocation();
	const [initialPrevPath] = usePrevPath(location);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		if (user) {
			navigate(initialPrevPath || -1);
			setIsLoading(false);
		}
	}, [user]);
	useEffect(() => {
		setIsLoading(false);
	}, [type]);

	const handleSubmit = (values, actions) => {
		const {handleLogin} = props;
		handleLogin(values);
		actions.setSubmitting(false);
		actions.resetForm();
		setIsLoading(true);
	};

	return (
		<>
			{isLoading ? (
				<Spinner />
			) : (
				<FormFieldContainer>
					<Formik
						validationSchema={LoginSchema}
						initialValues={{username: '', password: ''}}
						onSubmit={handleSubmit}
						render={() => (
							<Form>
								<Section center>
									<FormRow>
										<Column xl={6} xs={12}>
											<Input label="Email *" name="username" />
										</Column>
									</FormRow>
									<FormRow>
										<Column xl={6} xs={12}>
											<PasswordInput label="Passwort *" name="password" />
										</Column>
									</FormRow>
									<FormRow>
										<Column>
											<ButtonStyled text="Anmelden" theme="candy" type="submit" />
										</Column>
									</FormRow>
									<FormRow>
										<Column>
											<LinkStyled to="/registrieren">
												Noch kein Mitglied? Zur Registrierung
											</LinkStyled>
										</Column>
										<Column>
											<LinkStyled to="/neues-passwort-beantragen">Passwort vergessen?</LinkStyled>
										</Column>
									</FormRow>
								</Section>
							</Form>
						)}
					/>
				</FormFieldContainer>
			)}
		</>
	);
};

const mapStateToProps = (state) => ({
	user: state.userManagement.user,
	type: !!state.messages.error,
});
const mapDispatchToProps = (dispatch) => ({
	handleLogin: (data) => dispatch(userLogin(data)),
});

Login.propTypes = {
	user: PropTypes.shape({
		UserId: PropTypes.string,
	}),
	handleLogin: PropTypes.func,
	type: PropTypes.bool,
};

Login.defaultProps = {
	user: null,
	handleLogin: undefined,
	type: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);

import React from 'react';
import {StaticQuery, graphql} from 'gatsby';
import Sidebar from './Sidebar';

const SidebarWrapper = ({...props}) => (
	<StaticQuery
		query={graphql`
			query {
				allWordpressWpApiMenusMenusItems(filter: {slug: {eq: "main-menu"}}) {
					edges {
						node {
							id
							name
							items {
								path
								title
								object_slug
								wordpress_children {
									title
									path
									object_slug
									wordpress_children {
										title
										path
									}
								}
							}
						}
					}
				}
			}
		`}
		render={data => <Sidebar data={data} {...props} />}
	/>
);

export default SidebarWrapper;

/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Sticky from 'react-sticky-el';
import {navigate} from '@reach/router';
import Section from '../../components/Layout/Section/Section';
import Row from '../../components/Layout/Grid/Row';
import Column from '../../components/Layout/Grid/Column';
import SidebarWrapper from '../../components/Layout/Sidebar/SidebarWrapper';
import Headline from '../../components/Elements/Headline/Headline';
import Glossary from '../../components/Content/Glossary/Glossary';
import ResetPZNButton from '../../components/FunctionalElements/ResetPZNButton';
import {PageContentStyled, PageTemplateStyled, SectionFooterStyled, Flexdiv} from '../styles';
import Teaser from '../../components/Elements/Teaser/Teaser';
import ServiceTeaser from '../../components/Elements/Teaser/ServiceTeaser360/ServiceTeaser';

import {HeadlineStyledH3} from '../../components/Elements/Headline/styles';
import Meta, {appendSeoTitleSuffix} from '../../components/Layout/Meta';
import ReleaseNumber from '../../components/Elements/ReleaseNumber/ReleaseNumber';
import ContactSection from '../../components/Content/ContactSection/ContactSection';
import DynamicPage from '../../components/Container/DynamicPage/DynamicPage';
import {content} from '../../assets/language/de';
import LoginReminder from '../../components/Container/Login/LoginReminder/LoginReminder';
import LogoService from '../../components/Layout/Logo/LogoService';
import config from '../../config';
import ShareSocialMedia from '../../components/Container/ShareSocialMedia/ShareSocialMedia';

const buildRelatedContentSection = (relatedContent, headline) => {
	let relatedContentRow = null;
	let relatedContentSection = null;
	const useServiceTeaser = typeof window !== 'undefined' ? window.location.href.indexOf('service') !== -1 : false;

	if (relatedContent && relatedContent.length > 0) {
		relatedContentRow = relatedContent.map((relatedPost, index) => {
			const teaserItem = {
				appearance: useServiceTeaser ? 'shadow' : 'relatedContent',
				title: relatedPost.title,
				label: '',
				link: {
					url: `${relatedPost.slug}`,
					title: relatedPost.title,
					target: '_self',
				},
				image: {
					source_url: '',
					alt_text: relatedPost.title,
					title: relatedPost.title,
					localFile: {
						publicURL: relatedPost.thumbnail,
					},
				},
			};
			return (
				<Column key={relatedPost.id} lg={4}>
					{useServiceTeaser ? (
						<ServiceTeaser position={index + 1} item={teaserItem} />
					) : (
						<Teaser position={index + 1} item={teaserItem} />
					)}
				</Column>
			);
		});
		relatedContentSection = (
			<Section showPadding theme="grey">
				<Row>
					<Column>
						<HeadlineStyledH3>{headline}</HeadlineStyledH3>
					</Column>
				</Row>
				<Row>{relatedContentRow}</Row>
			</Section>
		);
	}
	return relatedContentSection;
};

const SidebarTemplateDynamic = (props) => {
	const {page, title, location, relatedContent, pageParent, releaseNumber, seoDesc, user, pzn} = props;
	let slug = location.pathname.split('/');
	slug = slug.filter((el) => {
		return el !== null && el !== '';
	});
	const resetBtn = slug.includes('ms-behandlung');
	const isWirkstoff = slug.includes('ms-behandlung');
	const blackList = config.login.specialProducts.split(',');

	const relatedContentSection = buildRelatedContentSection(relatedContent, content.relatedContent.headline);

	let category = 'Multiple Sklerose';
	if (pageParent && pageParent.title) {
		category = pageParent.title;
	}

	if ((pzn === undefined && isWirkstoff) || (isWirkstoff && !slug.includes(pzn.slug))) {
		navigate('/service/ms-behandlung');
		return null;
	}

	if (
		!pzn &&
		blackList.some((prod) => location.pathname.includes(prod)) &&
		location.pathname.includes('international')
	) {
		navigate('/spezial/international');
		return null;
	}

	return (
		<>
			<Meta title={appendSeoTitleSuffix(title)} description={seoDesc} keywords={category} />
			<PageTemplateStyled className="page_template">
				<Section showPadding className="stickArea">
					<Row>
						<Column lg={3} xxl={2}>
							<Sticky
								topOffset={-80}
								bottomOffset={80}
								boundaryElement=".stickArea"
								hideOnBoundaryHit={false}
								stickyStyle={{marginTop: '60px'}}>
								<SidebarWrapper slug={slug} activePath={location.pathname} />
							</Sticky>
						</Column>
						<Column lg={9}>
							<Section className="content" pageParent={pageParent}>
								<PageContentStyled>
									<div className="category" style={{display: 'none'}}>
										{location.pathname.split('/')[1]}
									</div>
									<>
										{location.pathname.startsWith('/service/') && <LogoService />}
										{user || resetBtn ? <Headline itemScope as="h1" text={title} /> : null}
										{resetBtn && <ResetPZNButton />}
										{(pzn && location.pathname.includes(pzn.slug)) || user || resetBtn ? (
											<DynamicPage page={page} {...props} />
										) : (
											<LoginReminder />
										)}
									</>
								</PageContentStyled>
								<ReleaseNumber releaseNumber={releaseNumber} />
							</Section>
						</Column>
					</Row>
				</Section>
				<ContactSection />
				{relatedContentSection}
			</PageTemplateStyled>
		</>
	);
};

export const SidebarTemplate = (props) => {
	const {title, location, components, relatedContent, pageParent, releaseNumber, seoDesc} = props;
	let slug = location.pathname.split('/');
	slug = slug.filter((el) => {
		return el !== null && el !== '';
	});
	const relatedContentSection = buildRelatedContentSection(relatedContent, content.relatedContent.headline);
	const glossary = (pageTitle) => {
		let glossaryContent = '';
		if (pageTitle === 'Glossar') {
			glossaryContent = <Glossary />;
		}
		return glossaryContent;
	};

	let category = 'Multiple Sklerose';
	if (pageParent && pageParent.title) {
		category = pageParent.title;
	}

	// console.log('MS', location.pathname.startsWith('/multiple-sklerose'));

	return (
		<>
			<Meta title={appendSeoTitleSuffix(title)} description={seoDesc} keywords={category} />
			<PageTemplateStyled className="page_template">
				<Section showPadding className="stickArea">
					<Row>
						<Column lg={3} xxl={2}>
							<Sticky
								topOffset={-80}
								bottomOffset={80}
								boundaryElement=".stickArea"
								hideOnBoundaryHit={false}
								stickyStyle={{marginTop: '60px'}}>
								<SidebarWrapper slug={slug} activePath={location.pathname} />
							</Sticky>
						</Column>
						<Column lg={9}>
							<Section className="content" pageParent={pageParent}>
								<PageContentStyled>
									<div className="category" style={{display: 'none'}}>
										{location.pathname.split('/')[1]}
									</div>
									<>
										<Flexdiv>
											<Headline itemScope as="h1" text={title} />
											{location.pathname.startsWith('/service/') && <LogoService />}
										</Flexdiv>

										{components}
										{glossary(title)}
									</>
								</PageContentStyled>
								<SectionFooterStyled>
									<ReleaseNumber releaseNumber={releaseNumber} />
									{location.pathname.startsWith('/multiple-sklerose') && (
										<ShareSocialMedia title={title} location={location} />
									)}
								</SectionFooterStyled>
							</Section>
						</Column>
					</Row>
				</Section>
				<ContactSection />
				{relatedContentSection}
			</PageTemplateStyled>
		</>
	);
};

const mapStateToProps = (state) => ({
	user: state.userManagement.user,
	pzn: state.userManagement.pzn,
});

SidebarTemplate.propTypes = {
	title: PropTypes.string.isRequired,
	location: PropTypes.shape({
		href: PropTypes.string,
		pathname: PropTypes.string,
	}).isRequired,
	components: PropTypes.oneOfType([PropTypes.array, PropTypes.element]),
	pageParent: PropTypes.shape({slug: PropTypes.string, title: PropTypes.string}),
	releaseNumber: PropTypes.string,
	seoDesc: PropTypes.string,
	relatedContent: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.number,
			title: PropTypes.string,
			slug: PropTypes.string,
			thumbnail: PropTypes.shape({
				srcSet: PropTypes.string,
				src: PropTypes.string,
			}),
		})
	),
};

SidebarTemplate.defaultProps = {
	relatedContent: [],
	components: null,
	pageParent: null,
	releaseNumber: null,
	seoDesc: null,
};

export default connect(mapStateToProps)(SidebarTemplateDynamic);

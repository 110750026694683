import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {buildComponents} from '../../../utils/fetchWPContent';
import {renderComponents} from '../../../utils/dynamicRenderer';
import LoadingSpinner from '../../Elements/LoadingSpinner/LoadingSpinner';

const CONTENT_TYPE_TO_FETCH = 'pages';

export default class DynamicPage extends Component {
	state = {wpPage: null};

	componentDidMount = () => {
		const {wordpressId, page} = this.props;

		buildComponents(wordpressId, page, CONTENT_TYPE_TO_FETCH).then((wpPage) => {
			this.setState({wpPage});
		});
	};

	componentDidUpdate = (prevState) => {
		if (prevState.location.hash.length > 0) {
			const id = decodeURI(prevState.location.hash.replace('#', '').replace('/', ''));

			const element = document.getElementById(id);
			if (element) element.scrollIntoView();
		}
	};

	renderComponentsDynamically = (page) => {
		if (page.components) {
			const components = page.components;
			const rowLength = page.rowLength;
			return renderComponents(components, rowLength);
		}
		return null;
	};

	render() {
		const {wpPage} = this.state;

		if (wpPage === null) {
			return <LoadingSpinner />;
		}
		return <React.Fragment>{this.renderComponentsDynamically(wpPage)}</React.Fragment>;
	}
}

DynamicPage.propTypes = {
	wordpressId: PropTypes.number.isRequired,
};

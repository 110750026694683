import styled, {keyframes} from 'styled-components';

import {colors} from 'styles/variables';

const spinAnimation = keyframes`
0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
`;

export const Loader = styled.div`
	border: 0.5rem solid #f3f3f3;
	border-radius: 50%;
	border-top: 0.5rem solid ${colors.candy};
	width: 3rem;
	height: 3rem;
	animation-name: ${spinAnimation};
	animation-duration: 2s;
	animation-iteration-count: infinite;
`;

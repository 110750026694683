import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import config from '../../../config';
import {Link} from 'gatsby';
import {
	SidebarStyled,
	SidebarItemStyled,
	SidebarItemsStyled,
	SidebarItemLink,
	IconStyled,
	HeadlineStyled,
} from './styles';

function testIfAuth(path, BlackList, pzn) {
	let pznSlug = null;

	if (pzn !== null) {
		pznSlug = pzn.slug;
	}

	for (let i = 0; i < BlackList.length; i += 1) {
		if (path.includes(pznSlug)) {
			return true;
		}
		if (path.includes(BlackList[i])) {
			return false;
		}
	}
	return true;
}

const BlackList = config.login.special.split(',');

// const SecuredPages = config.login.securePages.split(',');

class Sidebar extends Component {
	constructor(props) {
		super(props);
		// this.handleLevel1ItemClick = this.handleLevel1ItemClick.bind(this);
		this.renderLevel2MenuItems = this.renderLevel2MenuItems.bind(this);

		const {
			slug,
			activePath,
			data: {allWordpressWpApiMenusMenusItems},
		} = this.props;

		const menuItems = allWordpressWpApiMenusMenusItems.edges[0].node.items;
		const nodes = menuItems.filter((item) => {
			return item.path === slug[0];
		});

		this.state = {
			sidebarTitle: nodes.length > 0 ? nodes[0].title : '',
			activePath,
			subMenuSelected: activePath,
			subMenuIsOpen: slug.length > 0,
			nodes,
		};
	}

	renderLevel2MenuItems(menuItem) {
		const {activePath, subMenuSelected, subMenuIsOpen, nodes} = this.state;
		const {pzn, user} = this.props;
		// user ? (pzn = user.pzn) : '';
		let content = null;
		if (menuItem.path) {
			if (!subMenuSelected.includes(menuItem.path)) {
				return null;
			}
			if (menuItem.object_slug === subMenuSelected && subMenuIsOpen === false) {
				return null;
			}
		}
		const subMenuItems = nodes[0].wordpress_children.filter((item) => {
			return subMenuSelected.includes(item.path);
		});
		const items = subMenuItems[0];

		if (items && items.wordpress_children) {
			content = (
				<SidebarItemsStyled level={2}>
					{items.wordpress_children.map(
						(item) =>
							testIfAuth(item.path, BlackList, pzn) && (
								<SidebarItemStyled level={2} key={item.title}>
									<SidebarItemLink
										level={2}
										to={`/${item.path}`}
										active={activePath.includes(item.path) ? 1 : 0}>
										<span> {item.title} </span>
									</SidebarItemLink>
								</SidebarItemStyled>
							)
					)}
				</SidebarItemsStyled>
			);
		}
		return content;
	}

	renderSidebarTitle() {
		const {sidebarTitle} = this.state;
		return sidebarTitle;
	}

	renderLevel1MenuItems() {
		const {activePath, nodes} = this.state;

		return (
			<SidebarItemsStyled level={1}>
				{nodes[0].wordpress_children.map((item) => (
					<SidebarItemStyled level={1} key={item.object_slug}>
						<SidebarItemLink level={1} to={`/${item.path}`} active={activePath.includes(item.path) ? 1 : 0}>
							<span> {item.title} </span> <IconStyled name="chevron_right" />
						</SidebarItemLink>
						{this.renderLevel2MenuItems(item)}
					</SidebarItemStyled>
				))}
			</SidebarItemsStyled>
		);
	}

	render() {
		const {nodes} = this.state;

		const _title = this.renderSidebarTitle();
		const _link = _title.includes('Services') ? 'service' : _title.toLowerCase().replace(/ /g, '-');

		if (nodes.length > 0 && nodes[0].wordpress_children !== null) {
			return (
				<SidebarStyled className="sidebar">
					<Link to={`/${_link}`}>
						<HeadlineStyled> {_title} </HeadlineStyled>
					</Link>
					{this.renderLevel1MenuItems()}
				</SidebarStyled>
			);
		}

		return null;
	}
}

const mapStateToProps = (state) => ({
	user: state.userManagement.user,
	pzn: state.userManagement.pzn,
});

export default connect(mapStateToProps)(Sidebar);

Sidebar.propTypes = {
	user: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	pzn: PropTypes.objectOf(PropTypes.string),
	data: PropTypes.objectOf(PropTypes.object).isRequired,
	slug: PropTypes.arrayOf(PropTypes.string).isRequired,
	activePath: PropTypes.string.isRequired,
};

Sidebar.defaultProps = {
	user: null,
	pzn: null,
};

import React from 'react';
import PropTypes from 'prop-types';
import {GlossaryItemItems, GlossaryItemHeadline, GlossaryItemText} from './styles';

const GlossaryBlock = (props) => {
	const {items} = props;

	return (
		<>
			{Object.keys(items).map((key) => (
				<GlossaryItemItems key={`glossary-block-${key}`}>
					<GlossaryItemHeadline>{items[key][0]}</GlossaryItemHeadline>
					<GlossaryItemText>{items[key][1]}</GlossaryItemText>
				</GlossaryItemItems>
			))}
		</>
	);
};

GlossaryBlock.propTypes = {
	items: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.object)).isRequired,
};

export default GlossaryBlock;

import React from 'react';
import Login from '../Login';
import {SidebarLoginStyled, HeadlineStyled} from '../styles';
import Text from '../../../Elements/Text/Text';

const LoginReminder = () => (
	<SidebarLoginStyled>
		<HeadlineStyled>Dieser Inhalt ist Login-geschützt</HeadlineStyled>
		<Text content="Bereits registriert? Bitte hier einloggen." />
		<Login />
	</SidebarLoginStyled>
);

export default LoginReminder;

const glossaryData = {
	Acetylcholin:
		'Neurotransmitter, der in den Synapsen verschiedener Anteile des Nervensystems gespeichert ist; bei seiner Freisetzung in den synaptischen Spalt durch einen Nervenimpuls sorgt das Acetylcholin wiederum mit dafür, dass dieser Nervenimpuls weitergeleitet wird.',
	Anticholinergika:
		'Substanzen, die die Wirkung von Acetylcholin in den Nervenendigungen (Synapsen) unterdrücken; solche Substanzen werden z. B. in Medikamenten gegen bestimmte Blasenstörungen eingesetzt.',
	Antigen:
		'Ein Antigen ist eine Substanz, die eine Immunantwort, d.h. es werden unter anderem Antikörper gebildet, auslöst. Antigene, oft Proteine oder Polysaccharide, befinden sich auf der Oberfläche von Krankheitserregern und werden vom Immunsystem als fremd erkannt.',
	Antikörper:
		'Antikörper sind spezialisierte Proteine, die vom Immunsystem produziert werden, um spezifische Antigene zu erkennen und zu binden. Sie spielen eine zentrale Rolle in der Immunantwort, indem sie Krankheitserreger wie Viren und Bakterien markieren, sodass andere Immunzellen diese leichter eliminieren können.',

	Antispastika:
		'Antispastika sind Medikamente, die zur Linderung von Spastiken verwendet werden. Antispastika wirken, indem sie die Übertragung von Nervensignalen, die Muskelspannungen auslösen, reduzieren oder blockieren, was zu einer Entspannung der Muskeln führt und so die Beweglichkeit verbessert und Schmerzen verringern können.',
	Ataxie:
		'Ataxie bezeichnet eine Störung der Bewegungskoordination, die auf eine Beeinträchtigung der sensorischen Signale im zentralen Nervensystem zurückzuführen ist. Personen mit Ataxie haben Schwierigkeiten bei der Koordination ihrer Bewegungen, was zu ungeschickten oder übertriebenen Aktionen führen kann.',
	Autoimmunerkrankung:
		'Eine Autoimmunerkrankung entsteht, wenn das Immunsystem fälschlicherweise körpereigene Zellen und Gewebe als fremd wahrnimmt und angreift. Dies führt zu Entzündungen und Schäden in verschiedenen Teilen des Körpers. Beispiele für Autoimmunerkrankungen sind Typ-1-Diabetes, bei dem die Insulin-produzierenden Zellen der Bauchspeicheldrüse angegriffen werden, rheumatoide Arthritis, die die Gelenke betrifft, und Multiple Sklerose.',
	Axon:
		'Das Axon ist ein Teil der Nervenzelle. Der lange, fadenförmige Fortsatz dient dazu, elektrische Impulse von einer Nervenzelle zu anderen Nervenzellen, Muskeln oder Drüsen zu leiten. Axone können sehr kurz oder bis zu einem Meter lang sein und sind oft von einer isolierenden Schicht, der Myelinscheide, umgeben, die die Übertragungsgeschwindigkeit der elektrischen Signale erhöht.',
	'Babinski-Zeichen/Babinski-Phänomen':
		'Benannt nach dem französischen Neurologen Joseph Francois Félix Babinski (1857–1932); bezeichnet das Strecken der Großzehe in Richtung Kopf, bei Bestreichen der seitlichen Fußsohle (=Babinski positiv); zählt zu den Pyramidenbahnzeichen (siehe dort)',
	'Banden, oligoklonale':
		'Möglicher Teilbefund einer Nervenwasseruntersuchung (Liquorpunktion, LP); zeigt das Vorhandensein spezieller im zentralen Nervensystem (ZNS) gebildeter Immunglobuline an; als sehr empfindliche, aber nicht spezifische Indikatoren für eine Multiple Sklerose können oligoklonale Banden auch bei anderen Erkrankungen des ZNS positiv sein.',
	Basistherapie:
		'Bezeichnet im Gegensatz zur Eskalationstherapie (siehe dort) die verlaufsmodifizierende Dauertherapie der MS bei schubförmigem Verlauf. Dazu zählen hauptsächlich Interferon beta-1a, Interferon beta-1b, Glatirameracetat, Peginterferon beta-1a, Dimethylfumarat und Teriflunomid.',
	'Blut-Hirn-Schranke (BHS)':
		'Die Blut-Hirn-Schranke ist eine selektive Barriere zwischen dem Blutkreislauf und dem Zentralnervensystem (ZNS). Sie wird von den Endothelzellen an der Innenwand der Blutkapillaren im Gehirn und den die Gefäße umgebenden Astrozyten (eine Form von Gliazellen) gebildet. Ihre Hauptfunktion ist es, das Gehirn vor dem Eindringen von schädlichen Substanzen zu schützen und ein stabiles Milieu für die Hirnfunktionen zu gewährleisten. Diese Barriere lässt Nährstoffe wie Glukose und Aminosäuren passieren, verhindert aber gleichzeitig das Eindringen vieler Toxine, Pathogene und sogar vieler Medikamente ins Gehirn.',
	'B-Lymphozyten':
		'B-Lymphozyten, auch als B-Zellen oder Plasmazellen bekannt, sind eine Art von weißen Blutkörperchen, die eine zentrale Rolle im adaptiven Immunsystem spielen. Sie sind für die Produktion von Antikörpern verantwortlich, die spezifisch auf Antigene reagieren können, die auf Pathogenen oder infizierten Zellen vorkommen. Wenn B-Lymphozyten auf ihr spezifisches Antigen treffen, werden sie aktiviert und differenzieren sich zu Plasmazellen, die große Mengen spezifischer Antikörper produzieren. Diese Antikörper helfen, Krankheitserreger zu neutralisieren, indem sie sie für die Zerstörung durch andere Immunzellen markieren.',
	Cerebellum: 'Kleinhirn',
	Cerebral: 'Das Gehirn betreffend',
	Computertomographie:
		'Computertomographie (CT) ist ein bildgebendes Verfahren, das mithilfe von Röntgenstrahlen detaillierte Querschnittsbilder des Körpers erzeugt. Bei der CT wird ein Röntgengerät um den Körper des Patienten rotiert, während gleichzeitig ein Computer die empfangenen Daten verarbeitet, um klare, detaillierte Bilder von Knochen, Organen, Blutgefäßen und anderen Strukturen zu erstellen.',
	Demyelinisierung:
		'Demyelinisierung, auch Entmarkung genannt, bezeichnet den Prozess des Verlusts oder der Beschädigung der Myelinscheide, einer fetthaltigen Schicht, die die Axone (Nervenfasern) in Teilen des zentralen Nervensystems umgibt. Myelin ist entscheidend für die schnelle und effiziente Übertragung elektrischer Impulse entlang der Nervenfasern. Wenn die Myelinscheide beschädigt wird, wird diese Signalübertragung verlangsamt oder blockiert, was zu einer Vielzahl neurologischer Symptome führen kann.',
	Dendriten:
		'Dendriten sind verzweigte Fortsätze, die vom Zellkörper der Nervenzellen abgehen. Dendriten sind mit spezialisierten Strukturen, den Synapsen, ausgestattet, an denen sie chemische Signale von den Axonen anderer Nervenzellen empfangen. Diese Signale werden dann in Form elektrischer Impulse in den Zellkörper weitergeleitet, wo sie verarbeitet werden. Die Struktur und Komplexität der Dendriten spielen eine wichtige Rolle bei der Lern- und Gedächtnisfunktion, da sie die Verbindungspunkte für die Kommunikation zwischen Neuronen darstellen.',
	'Devic-Syndrom':
		'Auch Neuromyelitis optica genannt, benannt nach dem französischen Arzt Eugene Devic (1869-1930); wahrscheinlich eine Sonderform der MS. Akut kommt es zu einer doppelseitigen Entzündung des Sehnerves und entsprechender Sehstörung, gefolgt von einer aufsteigenden Entzündung des Rückenmarks mit Querschnittslähmung; Auftreten bei jungen Patienten',
	Dysarthrie:
		'Sprachstörung, bei der insbesondere die Koordination der beim Sprechen beteiligten Muskeln gestört ist.',
	Dysmetrie:
		'Dysmetrie ist eine neurologische Störung und bezeichnet eine Störung von willkürlichen Bewegungsabläufen, die sich durch eine beeinträchtigte Fähigkeit auszeichnet, die Distanz, Geschwindigkeit oder Kraft von Bewegungen zu regulieren. Dies führt dazu, dass Betroffene nicht in der Lage sind, Bewegungen präzise auszuführen. Typische Symptome einer Dysmetrie sind das Über- oder Unterschießen des Ziels bei zielgerichteten Bewegungen, wie beim Greifen nach einem Gegenstand oder beim Tippen.',
	EDSS:
		'Expanded Disability Status Scale, vereinfacht übersetzt: "Skala des Schweregrads der Behinderung"; es handelt sich dabei um eine 10-stufige Skala, mit Hilfe derer der aktuelle Beeinträchtigungsgrad bei MS-Patienten gemessen wird.',
	Entmarkung: 'Zerstörung der Myelinschicht oder Marksubstanz, die die Axone der Nervenfasern umgibt',
	'Erektile Dysfunktion':
		'Erektile Dysfunktion (ED), auch als Erektionsstörung bekannt, beschreibt den Zustand, wenn es einem Mann über einen gewissen Zeitraum hinweg in der Mehrzahl der Versuche nicht gelingt, eine ausreichende Erektion für befriedigenden Geschlechtsverkehr zu erzielen oder aufrechtzuerhalten. Diese Störung kann physische oder psychologische Ursachen haben und betrifft Männer aller Altersgruppen, wobei das Risiko mit dem Alter zunimmt.',
	Eskalationstherapie:
		'Eskalationstherapie – der nächste Schritt, wenn die MS trotz Basistherapie Krankheitsaktivität aufweist. Natalizumab und Fingolimod sind für den schubförmig remittierenden Verlauf der MS als Eskalationstherapien zugelassen. Mitoxantron kommt in erster Linie bei der sekundär progredienten MS zum Einsatz.',
	'Evozierte Potenziale':
		'Evozierte Potenziale sind Messungen der elektrischen Aktivität im Gehirn als Antwort auf spezifische sensorische oder kognitive Reize. Diese Technik wird in der Medizin verwendet, um die Funktionsfähigkeit verschiedener Teile des Nervensystems zu überprüfen. Es gibt verschiedene Arten von evozierten Potenzialen, die jeweils unterschiedliche Aspekte der Nervenfunktion untersuchen: Visuell evozierte Potenziale (VEP): Messen die Reaktion des Gehirns auf visuelle Reize, typischerweise Lichtblitze oder wechselnde Muster auf einem Bildschirm. VEPs werden verwendet, um die Funktion des Sehnervs und der visuellen Verarbeitungsbereiche im Gehirn zu überprüfen. Akustisch evozierte Potenziale (AEP), auch bekannt als auditorisch evozierte Potenziale: Prüfen die Reaktion des Gehirns auf hörbare Reize, wie Klicks oder Töne. Diese Messungen können dabei helfen, die Integrität der Hörbahnen vom Ohr bis zum Gehirn zu beurteilen. Die Aufzeichnung von evozierten Potenzialen erfolgt mittels Elektroden, die auf der Kopfhaut platziert werden, während der Patient den spezifischen Reizen ausgesetzt ist. Diese Messungen sind besonders wertvoll, weil sie nicht invasiv sind und präzise Informationen über die Geschwindigkeit und Effizienz der Nervenleitung liefern.',
	'Faszikulationen/Faszikulieren': 'Regelloses, blitzartiges, leichtes Muskelzucken',
	Fatigue: 'Eine besonders stark ausgeprägte Form der Müdigkeit, die bei der MS sehr häufig ist.',
	'Frühsommer-Meningoenzephalitis (FSME)':
		'Hirnhautentzündung, die durch das FSME-Virus verursacht und mittels Zeckenbiss übertragen wird',
	Gabapentin:
		'Ein Medikament zur Behandlung der Epilepsie; zeigt aber gute Wirksamkeit bei der Schmerzbehandlung bei MS',
	Generikum:
		'Ein Generikum ist eine Nachahmung eines bereits existierenden Medikaments, das denselben Wirkstoff, Dosierung und Verabreichungsform hat. Nachdem das Patent eines Originalmedikaments abgelaufen ist, dürfen andere Hersteller diese Generika produzieren und verkaufen. Generika müssen durch die zuständigen Zulassungsbehörden als bioäquivalent zum Originalpräparat bewertet werden, was bedeutet, dass sie in ihrer Wirksamkeit, Sicherheit und Qualität mit dem Originalmedikament vergleichbar sind.',
	Gesichtsfeld: 'Bezeichnet den Teil der Umwelt, der bei unbewegter Kopfhaltung vom Patienten gesehen wird',
	Glia: 'Stützgewebe im zentralen Nervensystem, bildet auch die Nervenscheiden um die Axone (siehe dort)',
	'Hemi/hemi-': 'Vorsilbe; Bedeutung: "halb/teilweise"',
	Hemiparese: 'Halbseitenlähmung',
	Hippotherapie: 'Reittherapie',
	Hirnatrophie:
		'Hirnatrophie bezieht sich auf den Verlust von Neuronen sowie die Degeneration von Verbindungen zwischen Nervenzellen im Gehirn. Diese Abnahme des Hirngewebes führt zu einer Schrumpfung des Gehirns und ist oft ein normaler Bestandteil des Alterungsprozesses, kann jedoch auch durch verschiedene Krankheiten beschleunigt werden.',
	Hypalgesie: 'Herabgesetztes Schmerzempfinden',
	Hypästhesie: 'Herabgesetztes Berührungsempfinden',
	'Hypo-': 'Vorsilbe; Bedeutung: "vermindert/herabgesetzt"',
	'Hyper-': 'Vorsilbe; Bedeutung: "vermehrt/gesteigert"',
	Hyperalgesie: 'Überempfindlichkeit auf Schmerzreize',
	Hyperästhesie: 'Überempfindlichkeit auf Berührungen',
	'Immunmodulatorische Stufenbehandlung':
		'Therapiekonzept zur Behandlung der Multiplen Sklerose; bestehend aus Schubtherapie und verlaufsmodifizierender Therapie',
	Immunsystem:
		'Das Immunsystem ist ein komplexes Netzwerk aus Zellen, Geweben und Organen, das den Körper vor Krankheiten und Infektionen schützt. Es erkennt und reagiert auf eine Vielzahl von Bedrohungen, einschließlich Viren, Bakterien, Parasiten und Pilze, und hilft bei der Reparatur von Gewebeschäden und der Bekämpfung von Krankheiten. Das Immunsystem kann in zwei Hauptkomponenten unterteilt werden: Angeborenes Immunsystem: Dies ist die erste Verteidigungslinie des Körpers und reagiert schnell auf Eindringlinge. Es umfasst physische Barrieren wie Haut und Schleimhäute, chemische Barrieren wie Magensäure und Proteine in Tränen und Speichel sowie Fresszellen (Phagozyten), die Pathogene erkennen und vernichten. Adaptives (erworbenes) Immunsystem: Dieser Teil des Immunsystems entwickelt sich im Laufe des Lebens, wenn der Körper auf spezifische Krankheitserreger trifft. Es ist spezialisierter und kann gezielt auf spezifische Bedrohungen reagieren. Die Hauptakteure sind T-Lymphozyten (T-Zellen), die infizierte Zellen erkennen und zerstören, und B-Lymphozyten (B-Zellen), die Antikörper produzieren, um Pathogene zu neutralisieren.',
	Immunsuppressiva: 'Medikamente, die körpereigene Immunreaktionen teilweise oder ganz hemmen.',
	Inkontinenz:
		'Durch das Unvermögen, willkürlich Harn oder Stuhl zurückhalten zu können, kann es auch zu unfreiwilligem Urin- oder Stuhlabgang kommen.',
	Interferon:
		'Botenstoff des Immunsystems; insgesamt existieren drei Interferone: Interferon alpha, beta und gamma, die der Körper vor allem bei Virusinfektionen bildet und die die Viren daran hindern, sich zu vermehren. Zur Behandlung der Multiplen Sklerose kommt Interferon beta zum Einsatz.',
	'Kernspintomographie/Magnetresonanztomographie (MRT)':
		'Bildgebendes Verfahren ähnlich der Computertomographie; liefert Schicht-/Scheibenaufnahmen des untersuchten Organs; nutzt Magnetfelder statt Röntgenstrahlung; gehört zur Standarddiagnostik bei MS',
	Klonus: 'Unwillkürliche, rhythmische, krampfartige Muskelkontraktion',
	Kognition:
		'Umfasst die sogenannten höheren Gehirnfunktionen und damit alle Fähigkeiten, die mit Wahrnehmen, Denken, Planen und Merken zu tun haben',
	Koordination: 'Abstimmung und Zusammenwirken von Muskelbewegungen',
	kongenital: 'Angeboren',
	Kortison:
		'Körpereigenes Hormon; hemmt beispielsweise Entzündungen und unterdrückt immunologische/allergische Reaktionen',
	'Kreuzreaktion, kreuzreaktiv':
		'Wenn Antikörper oder besonders sensibilisierte T-Lymphozyten (oft auch einfach T-Zellen genannt) sich nicht nur gegen "ihre speziellen" Antigene richten, kommt es zu einer Kreuzreaktion. Dann erkennen die Antikörper und T-Zellen auch andere Strukturen (Antigene) als fremd, die es manchmal gar nicht sind, und lösen eine immunologische Reaktion, z.B. eine Entzündung, aus.',
	Läsionen:
		'MS-Läsionen, auch bekannt als Plaques oder Entzündungsherde, sind beschädigte Bereiche im zentralen Nervensystem (ZNS), die charakteristisch für Multiple Sklerose (MS) sind. Diese Läsionen entstehen, wenn das Immunsystem fälschlicherweise die Myelinscheide angreift. Der Verlust von Myelin, bekannt als Demyelinisierung, stört die Fähigkeit der Nerven, elektrische Signale effizient zu übertragen, was zu einer Vielzahl neurologischer Symptome führt. MS-Läsionen können überall im ZNS auftreten, einschließlich des Gehirns, des Rückenmarks und der Sehnerven. Ihre Lage und Größe bestimmen die Art und Schwere der Symptome. Die MRT kann als bildgebendes Verfahren diese Läsionen sichtbar machen.',
	'Lhermitte-Zeichen':
		'Nackenbeugezeichen: Durch Vorbeugen des Kopfes wird eine unangenehme, elektrisierende Missempfindung ausgelöst, die sich entlang der Wirbelsäule ausbreitet; häufig bei der MS, wird aber auch bei anderen Erkrankungen gefunden',
	Liquor:
		'Liquor, auch Nervenwasser genannt, bezeichnet die klare, farblose Flüssigkeit, die das Gehirn und das Rückenmark umgibt und schützt. Liquor erfüllt mehrere wichtige Funktionen im zentralen Nervensystem: Schutz: Liquor wirkt als eine Art Stoßdämpfer, der das Gehirn und das Rückenmark vor Verletzungen durch Stöße oder plötzliche Bewegungen schützt. Aufrechterhaltung des chemischen Gleichgewichts: Er hilft, das chemische Umfeld des Gehirns und des Rückenmarks zu regulieren, was für eine optimale Funktion der Nervenzellen notwendig ist. Ausscheidung von Abfallstoffen: Liquor transportiert Stoffwechselabfälle von den Gehirnzellen weg und führt sie dem Blutkreislauf zu, von wo aus sie ausgeschieden werden. In der medizinischen Diagnostik kann die Untersuchung des Liquors durch eine Lumbalpunktion Aufschluss über eine Vielzahl von Erkrankungen geben, darunter Infektionen (wie Meningitis), neurologische Erkrankungen (wie Multiple Sklerose) und Blutungen im Zentralnervensystem.',
	Lumbalpunktion:
		'Eine Lumbalpunktion, auch als Rückenmarkpunktion oder Spinalpunktion bekannt, ist ein medizinisches Verfahren, bei dem eine spezielle Hohlnadel auf der Höhe der unteren Lendenwirbelsäule zwischen den Wirbelkörpern eingeführt wird, um Liquor für weitere Untersuchungen zu entnehmen. Die Prozedur ist bei sachgerechter Anwendung ungefährlich und weitgehend schmerzfrei.',
	'Magnetresonanztomographie (MRT)/Kernspintomographie':
		'Magnetresonanztomographie (MRT), auch als Kernspintomographie bekannt, ist ein bildgebendes Verfahren, das starke Magnetfelder und Radiowellen nutzt, um detaillierte Bilder des Inneren des Körpers zu erstellen. Im Gegensatz zur Computertomographie (CT), die Röntgenstrahlen verwendet, kommt die MRT ohne ionisierende Strahlung aus. Die MRT ist besonders effektiv bei der Darstellung von Weichteilgewebe und wird daher häufig zur Untersuchung des Gehirns, der Wirbelsäule, der Gelenke und der inneren Organe wie Herz und Leber eingesetzt. Die MRT spielt eine wichtige Rolle bei der Diagnose und Verlaufsbeobachtung in der MS.',
	Markscheide:
		'Myelinschicht (Isolierschicht), die die Axone der Nervenfasern umgibt; sie wird im ZNS von den Oligodendrozyten und im peripheren Nervensystem (PNS) von den Schwannschen Zellen gebildet.',
	Marksubstanz:
		'Die so genannte weiße Substanz des ZNS; sie erscheint eher weiß, da überwiegend die von der Myelinschicht umgebenen langen Fortsätze (Axone) der Nervenfasern diese durchlaufen. Im Gehirn ist sie zentral gelegen und von der grauen Substanz (Hirnrinde) umgeben, im Rückenmark umgekehrt umgibt sie die graue Substanz.',
	Mobilität:
		'Mobilität ist ein Begriff für Beweglichkeit oder Bewegungsfähigkeit. Ein besonders wichtiger Aspekt der Mobilität ist das Gehen, aber auch das Einnehmen wechselnder Stellungen und Haltungen und das Tragen von Gegenständen gehören dazu.',
	'MS-Fatigue': 'Müdigkeit bei MS, die meist von besonderer Ausprägung ist',
	MSFC:
		'Bewertungsskala für die Beurteilung des Behinderungsgrads; einfache Anwendung; MSFC-Score (MS Functional Composite Score) ist eine breit angelegte Skala, die neben der Beinfunktion und der Gehfähigkeit auch die Armfunktion und die geistigen Fähigkeiten berücksichtigt. Damit spiegelt sie den Gesundheitszustand eines Patienten zuverlässig wider.',
	'MSWS-12':
		'Bewertungsskala für die Beurteilung der Gehfähigkeit; bei MSWS-12 (Multiple Sclerosis Walking Scale-12), auch Walk-12 genannt, wird die Gehfähigkeit aus Patientensicht mittels eines Fragebogens gemessen. ',
	Muskelrelaxans:
		'Muskelaxantien sind Medikamente, die zu einer Erschlaffung der Muskulatur führen. Sie werden eingesetzt bei Überanspannung von Muskeln, z.B. einer Spastik.',
	Myelin:
		'Myelin umgibt die Nervenfasern im zentralen Nervensystem (ZNS), das das Gehirn und das Rückenmark umfasst, in Form einer Isolierschicht und wird dann als Myelinscheide bezeichnet. Diese Schicht ist entscheidend für die schnelle und effiziente Übertragung elektrischer Impulse entlang der Nervenzellen. Myelin besteht hauptsächlich aus Lipiden (Fetten) und Proteinen. Myelin wird von den Ausläufern spezieller Gliazellen, der Oligodendrozyten, gebildet.',
	Myelinscheide:
		'Wie Markscheide: Isolierschicht, die die Axone der Nervenfasern umgibt; sie wird im ZNS von den Oligodendrozyten und im peripheren Nervensystem (PNS) von den Schwannschen Zellen gebildet.',
	Narkolepsie: 'Erkrankung mit unüberwindlichem Schlafzwang am Tage trotz ausreichendem Schlaf in der Nacht',
	'Neurodegeneration, neurodegenerativ':
		'Bei der "Degeneration" kommt es durch strukturelle und funktionelle Veränderungen (bis hin zu Zerstörung) in Geweben (oder Zellen) zu einer Funktionsminderung oder gar einem Verlust des betroffenen Organsystems. Ursachen können z.B. Stoffwechselstörungen oder toxische (giftige) Einflüsse sein. Bei der "Neurodegeneration" ist von den beschriebenen Veränderungen das Nervengewebe betroffen.',
	Nervenfasern:
		'Nervenfasern sind lange, dünne Fortsätze von Nervenzellen (Neuronen), die elektrische Impulse über weite Strecken im Körper übertragen. Sie sind grundlegende Bestandteile des Nervensystems und spielen eine entscheidende Rolle in der Kommunikation zwischen dem Gehirn, dem Rückenmark und verschiedenen Körperteilen.',
	Nystagmus:
		'Augenzittern; es gibt verschiedene Formen des Nystagmus, die bei der MS meist durch Entzündungsherde im Hirnstammbereich entstehen. Kann zu Sehstörungen oder Schwindel führen',
	'Oligoklonale Banden':
		'Oligoklonale Banden sind ein möglicher Teilbefund einer Untersuchung des Liquors. Oligoklonale Banden entstehen durch verschiedene Antikörper des Typs IgG oder IgM und können durch eine laborchemische Auftrennung von Liquor oder Blutserum sichtbar gemacht werden. Sie können ein Indikator für eine chronische Entzündung im Zentralnervensystem sein. Wenn sich im Liquor verglichen mit dem Serum vermehrt Banden zeigen, kann dies auf eine neurologische Erkrankung, insbesondere Multiple Sklerose, hinweisen.',
	Optikusneuritis:
		'Optikusneuritis ist eine Entzündung des Sehnervs, die typischerweise zu einer plötzlichen Reduktion der Sehschärfe oder des vollständigen Sehverlusts auf einem Auge führt. Die Erkrankung ist oft von Schmerzen begleitet, die sich bei Augenbewegungen verschlimmern. Optikusneuritis kann als isoliertes Phänomen auftreten, ist aber häufig mit bestimmten Erkrankungen wie Multipler Sklerose (MS) oder Neuromyelitis optica assoziiert.',
	Parästhesie:
		'Parästhesie beschreibt das Gefühl von abnormalen Empfindungen auf der Haut, die oft als Kribbeln, Prickeln, Taubheitsgefühl oder Brennen wahrgenommen werden. Diese Empfindungen treten spontan auf und können vorübergehend sein, wie das bekannte Gefühl, dass einem der "Arm oder das Bein eingeschlafen ist“. Es werden abhängig von der Empfindung verschiedene Formen der Parästhesien unterschieden',
	Parese:
		'Parese bezeichnet eine partielle oder unvollständige Lähmung, die mit einer Reduzierung der Muskelkraft in einem oder mehreren Muskeln verbunden ist. Anders als eine vollständige Lähmung (Paralyse), bei der überhaupt keine willkürliche Bewegung möglich ist, können Personen mit einer Parese noch eine gewisse Kontrolle und Bewegungsfähigkeit ihrer Muskeln aufweisen, allerdings in einem eingeschränkten Maß.',
	PASAT:
		'Ein häufig angewandtes neurologisches Testverfahren für die Messung der kognitiven Leistungsfähigkeit; beim PASAT (Paced Auditory Serial Addition Test) werden dem Patienten einfache Zahlen akustisch mitgeteilt, die er in einer bestimmten Zeit aufsummieren muss. ',
	'Paroxysmale Schmerzen':
		'Paroxysmale Schmerzen sind plötzlich auftretende, intensive Schmerzattacken, die kurz andauern und periodisch auftreten. Sie sind typischerweise sehr scharf und können ohne Vorwarnung beginnen und enden. Diese Art von Schmerz ist häufig bei bestimmten neurologischen Störungen zu finden.',
	Plaque:
		'Bei der MS werden so die entzündlichen, "fleckförmigen" Veränderungen (Entzündungsherde) in der weißen Substanz des ZNS bezeichnet.',
	Pyramidenbahn:
		'Leitungsbahn des zentralen Nervensystems; koordiniert Großhirnrinde und Kleinhirn bei der willkürlichen Bewegung von Muskeln; bei Schädigung kann es zu erhöhtem Muskeltonus, Störungen der Reflexe und Kloni kommen; lässt sich mittels der Pyramidenbahnzeichen überprüfen.',
	Pyramidenbahnzeichen:
		'Beim Erwachsenen krankhafte Reflexe; treten bei Schädigung der Pyramidenbahn auf (z.B. das Babinskiphänomen)',
	Randomisiert: 'Zufällig',
	Remission: 'Besserung bzw. Verschwinden von zuvor bestandener Symptome/Krankheitszeichen',
	Retrobulbärneuritis: 'Entzündung der Sehnerven',
	Schub:
		'Von einem Schub bei MS spricht man, wenn sich neue oder frühere Symptome innerhalb von Stunden oder Tagen entwickeln und wenn diese Beschwerden länger als einen Tag andauern und sie mit einem Abstand von mindestens einem Monat zum letzten Ereignis auftreten. Schübe können sich vollständig oder unvollständig zurückbilden. Bei einem Schub können Entzündungsherde neu auftreten oder alte Entzündungsherde reaktiviert werden. ',
	'Skandierende Sprache':
		'Bei dieser Art der Sprechstörung ist das fließende Sprechen gestört: der Betroffene spricht abgehackt, Pausen unterbrechen den Redefluss an falscher Stelle. Diese Sprechstörung tritt eher selten auf und ist durch eine Koordinationsstörung der Sprachmuskulatur bedingt. Dies ist bei der MS auf Entzündungsherde im Kleinhirnbereich zurückzuführen.',
	Spastik: 'Muskelsteifigkeit durch zentrale Störungen; d.h. im Gehirn oder Rückenmark (ZNS) gelegene Störungen',
	Subkutan: '"unter die Haut": Manche Medikamente werden auf diese Art und Weise per Injektion verabreicht.',
	Symptom: 'Beschwerde oder Krankheitszeichen',
	Synapsen:
		'Kontaktstellen zwischen Nervenzellen; über das Axon (siehe dort) eintreffende elektrische Impulse lösen an den Synapsen die Ausschüttung von Transmittersubstanzen aus. Das sind spezielle Eiweiße, die an der folgenden Nervenzelle den nächsten elektrischen Impuls zur Weiterleitung der Information auslösen.',
	'T1-Bilder/T1-gewichtet, T2-Bilder/T2-gewichtet': 'Untersuchungs-/Darstellungstechnik bei der Kernspintomographie',
	Tetraparese: 'Lähmung von Beinen und Armen',
	'T-Lymphozyten':
		'Kurz T-Zellen genannt; gehören zu den weißen Blutkörperchen (Leukozyten); sie spielen eine wichtige Rolle im menschlichen Immunsystem. Die T-Lymphozyten lassen sich wiederum grob weiter untergliedern in T-Helferzellen, die zur Steuerung der Immunantwort beitragen, und T-Killerzellen, die erkrankte oder als „fremd" erkannte Zellen direkt schädigen können.',
	Tremor:
		'Zittern häufig z.B. der Hände oder des Kopfes; man unterscheidet verschiedene Formen des Tremors, abhängig von der Ursache und Art des Tremors.',
	Trigeminusneuralgie:
		'Kurze, messerscharf einstechende Schmerzen in einer Gesichtshälfte (meistens sind Kinn- oder Wangenpartie betroffen), die sich ständig wiederholen; der Trigeminus ist einer der 12 Hirnerven, dessen Ursprungskerngebiet im Hirnstamm liegt. Schmerzen werden durch Läsionen im Hirnstamm verursacht.',
	'Uhthoff-Phänomen':
		'Benannt nach dem deutsche Augenarzt Wilhelm Uhthoff; bezeichnet die Tatsache, dass sich die Leitungsfähigkeit der Nerven bei höheren Temperaturen (bei Fieber, hoher Außentemperatur oder auch Anstrengung) verschlechtert; damit können sich die Symptome verstärken oder sogar neu auftreten, bilden sich jedoch nach einiger Zeit (Stunden) wieder zurück. ',
	Urtikaria: 'Nesselsucht, Quaddeln; gelegentlich als Nebenwirkung auftretende Hauterscheinung',
	'Verlaufsmodifizierende Therapie':
		'Die verlaufsmodifizierende Therapie ist eine Langzeittherapie bei schubförmiger MS. Man unterscheidet verlaufsmodifizierende Therapien für die milde und moderate Verlaufsform sowie Therapien für die (hoch-) aktive Verlaufsform der schubförmigen MS. Bei milder und moderater schubförmig remittierender MS kommen Präparate wie Peginterferon beta-1a, Interferon beta-1a, Interferon beta-1b, Dimethylfumarat, Glatirameracetat und Teriflunomid zum Einsatz. Natalizumab, Fingolimod und Alemtuzumab sind für die verlaufsmodifizierende Therapie bei (hoch-) aktiver Verlaufsform der schubförmig remittierenden MS zugelassen. Mitoxantron ist das Mittel der zweiten Wahl bei (hoch-) aktiver schubförmiger MS.',
	'Visuell evozierte Potenziale (VEP)':
		'Über die Haut am Hinterkopf abgeleitete elektrische Potenziale die nach optischer Reizung aufgezeichnet werden; dienen der Beurteilung der Sehbahn',
	Visusreduktion: 'Verminderung der Sehkraft',
	'Weiße Substanz': 'Im Wesentlichen aus markhaltigen Nervenfasern bestehender Teil des Zentralnervensystems (ZNS)',
	WHODie:
		'Die Weltgesundheitsorganisation (WHO) ist eine Sonderorganisation der Vereinten Nationen, die sich mit globalen öffentlichen Gesundheitsfragen befasst. Sie wurde am 7. April 1948 gegründet, ein Datum das jedes Jahr als Weltgesundheitstag gefeiert wird. Der Hauptsitz der WHO befindet sich in Genf, Schweiz. Die Hauptaufgaben der WHO bestehen in Gesundheitsförderung und -schutz, Krankheitsüberwachung und -prävention, Verfassung von internationalen Gesundheitsvorschriften und -standards sowie Bereitstellung von Gesundheitsinformationen und Förderung gesundheitspolitischer Debatten.',
	Wirkstoff:
		'Inhaltsstoff eines Medikamentes, der für die angestrebte Wirkung verantwortlich ist; daneben dienen Hilfsstoffe beispielsweise als Stabilisatoren oder Geschmacksstoffe.',
	'X-Chromosom':
		'Eines der beiden Geschlechtschromosomen, das unter dem Mikroskop an den Buchstaben X erinnert; nahezu alle Körperzellen der Frau tragen zwei X-Chromosomen, während beim Mann ein X- und ein Y-Chromosom vorhanden sind.',
	'Y-Chromosom':
		'Eines der beiden Geschlechtschromosomen, das unter dem Mikroskop an den Buchstaben Y erinnert und kleiner als das andere Geschlechtschromosom, das X-Chromosom ist; nahezu alle Körperzellen der Frau tragen zwei X-Chromosomen, während beim Mann ein X- und ein Y-Chromosom vorhanden sind.',
	'Zentrales Nervensystem (ZNS)':
		'Überbegriff für Gehirn und Rückenmark; unterschieden wird dagegen das periphere Nervensystem (PNS) und das autonome Nervensystem.',
	Zytokine: 'Lösliche Eiweiße für den Informationsaustausch zwischen den Zellen des Immunsystems',
};
export default glossaryData;

import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {graphql} from 'gatsby';
import Layout from '../components/Layout/Layout';
import PageTemplateDynamic, {PageTemplate} from './pages/page';
import {renderComponents} from '../utils/dynamicRenderer';
import SidebarTemplateDynamic, {SidebarTemplate} from './pages/sidebar';

const LOGGED_STATE = {
	LOGGED_IN: 'logged-in',
	ALWAYS: 'always',
	ANONYMOUS: 'anonymous',
};

const RENDER = {
	STATIC: 'static',
	DYNAMIC: 'dynamic',
	NONE: 'none',
};

class Page extends PureComponent {
	// eslint-disable-next-line react/state-in-constructor
	state = {render: RENDER.STATIC};

	componentDidMount = () => {
		const {
			data: {wordpressPage: page},
		} = this.props;

		if (page.acf.access === LOGGED_STATE.LOGGED_IN) {
			this.setState({render: RENDER.DYNAMIC});
		} else if (page.acf.access === LOGGED_STATE.ALWAYS) {
			this.setState({render: RENDER.STATIC});
		}
	};

	buildRelatedContent = () => {};

	renderComponentsDynamically = (page) => {
		const components = page.acf.components_page;
		const {
			acf: {rowLength},
		} = page;
		return renderComponents(components, rowLength);
	};

	renderStaticContent = (page) => {
		const {location} = this.props;
		const relatedContent = page.acf.related_content
			? page.acf.related_content.map((relatedPost) => {
					return {
						id: relatedPost.post.wordpress_id,
						title: relatedPost.post.post_title,
						slug: relatedPost.post.post_path,
						thumbnail:
							relatedPost.thumbnail &&
							relatedPost.thumbnail.url &&
							relatedPost.thumbnail.url.localFile &&
							relatedPost.thumbnail.url.localFile.childImageSharp
								? relatedPost.thumbnail.url.localFile.childImageSharp.fluid
								: null,
					};
			  })
			: [];

		const pageProps = {
			title: page.title,
			wordpressId: page.wordpress_id,
			pageParent: page.parent_element,
			releaseNumber: page.acf.release_number,
			access: page.acf.access,
			relatedContent,
			template: page.template,
			components: this.renderComponentsDynamically(page),
			location,
			seoDesc: page.yoast_meta.yoast_wpseo_metadesc,
		};

		const trackingProps = {
			wordpressId: page.wordpress_id,
			title: page.title,
			date: page.date,
		};

		if (page.template.includes('sidebar')) {
			return (
				<Layout location={location} trackingProps={trackingProps}>
					<SidebarTemplate {...pageProps} />
				</Layout>
			);
		}

		return (
			<Layout location={location} trackingProps={trackingProps}>
				<PageTemplate {...pageProps} />
			</Layout>
		);
	};

	// there must be a minimum payload from wordPress - even for protected content
	renderTestDynamic = (page) => {
		const {location} = this.props;
		const relatedContent = page.acf.related_content
			? page.acf.related_content.map((relatedPost) => {
					return {
						id: relatedPost.post.wordpress_id,
						title: relatedPost.post.post_title,
						slug: relatedPost.post.post_path,
						thumbnail:
							relatedPost.thumbnail &&
							relatedPost.thumbnail.url &&
							relatedPost.thumbnail.url.localFile &&
							relatedPost.thumbnail.url.localFile.childImageSharp
								? relatedPost.thumbnail.url.localFile.childImageSharp.fluid
								: null,
					};
			  })
			: [];

		const pageProps = {
			title: page.title,
			wordpressId: page.wordpress_id,
			pageParent: page.parent_element,
			releaseNumber: page.acf.release_number,
			rowLength: page.acf.rowLength,
			access: page.acf.access,
			relatedContent,
			components: page.acf.components_page,
			template: page.template,
			location,
			seoDesc: page.yoast_meta.yoast_wpseo_metadesc,
		};

		const trackingProps = {
			wordpressId: page.wordpress_id,
			title: page.title,
			date: page.date,
		};

		if (page.template.includes('sidebar') || page.template.includes('wirkstoff')) {
			return (
				<Layout location={location} trackingProps={trackingProps}>
					<SidebarTemplateDynamic page={pageProps} {...pageProps} />
				</Layout>
			);
		}

		return (
			<Layout location={location} trackingProps={trackingProps}>
				<PageTemplateDynamic page={pageProps} {...pageProps} />
			</Layout>
		);
	};

	render() {
		const {
			data: {wordpressPage: page},
		} = this.props;
		const {render} = this.state;

		if (render === RENDER.NONE) {
			return null;
		}
		if (render === RENDER.DYNAMIC) {
			return this.renderTestDynamic(page);
		}
		if (render === RENDER.STATIC) {
			return this.renderStaticContent(page);
		}
		return null;
	}
}

Page.propTypes = {
	data: PropTypes.objectOf(PropTypes.object).isRequired,
	location: PropTypes.shape({
		href: PropTypes.string,
		pathname: PropTypes.string,
	}).isRequired,
};

export default Page;

export const pageQuery = graphql`
	query PageById($id: String!) {
		wordpressPage(id: {eq: $id}) {
			title
			content
			template
			wordpress_id
			date(formatString: "MMMM DD, YYYY")
			parent_element {
				slug
				title
			}
			yoast_meta {
				yoast_wpseo_title
				yoast_wpseo_metadesc
			}
			acf {
				access
				hidden
				release_number
				rowLength
				related_content {
					post {
						wordpress_id
						post_title
						post_name
						post_path
					}
					thumbnail {
						url {
							localFile {
								childImageSharp {
									fluid(srcSetBreakpoints: [800, 250], quality: 80) {
										...GatsbyImageSharpFluid_noBase64
									}
								}
							}
						}
					}
				}
				components_page {
					__typename
					... on WordPressAcf_headline {
						belongsToRow
						alignItems
						layout
						column
						as
						text
					}
					... on WordPressAcf_text {
						belongsToRow
						alignItems
						layout
						column
						content
					}
					... on WordPressAcf_intro {
						belongsToRow
						alignItems
						layout
						column
						content
					}
					... on WordPressAcf_quote {
						belongsToRow
						alignItems
						layout
						column
						content
					}
					... on WordPressAcf_spotify {
						belongsToRow
						alignItems
						layout
						column
						playlist
					}

					... on WordPressAcf_fancy_divider {
						belongsToRow
						alignItems
						layout
						column
					}
					... on WordPressAcf_infobox {
						belongsToRow
						alignItems
						layout
						column
						content
						theme
					}
					... on WordPressAcf_list {
						belongsToRow
						alignItems
						layout
						column
						as
						items {
							key
							title
							text
						}
					}
					... on WordPressAcf_button {
						belongsToRow
						alignItems
						layout
						column
						button_theme
						link {
							title
							url
							target
						}
					}
					... on WordPressAcf_downloadbutton {
						belongsToRow
						alignItems
						layout
						column
						button_theme
						file {
							title
							filename
							url {
								localFile {
									publicURL
								}
							}
						}
					}
					... on WordPressAcf_image {
						belongsToRow
						alignItems
						layout
						column
						appearance
						alignment
						caption
						optional_link {
							url
						}
						max_width
						image {
							source_url
							alt_text
							title
							localFile {
								extension
								childImageSharp {
									id
									fluid(srcSetBreakpoints: [800, 250], quality: 80) {
										...GatsbyImageSharpFluid_noBase64
									}
								}
							}
						}
					}
					... on WordPressAcf_teasergroup {
						belongsToRow
						alignItems
						layout
						column
						items {
							key
							topline
							title
							theme
							download
							link {
								url
							}
							file {
								filename
								url {
									localFile {
										publicURL
									}
								}
							}
							image {
								localFile {
									childImageSharp {
										fluid(srcSetBreakpoints: [800, 250], quality: 80) {
											...GatsbyImageSharpFluid_noBase64
										}
									}
								}
							}
						}
					}
					... on WordPressAcf_serviceteaser {
						belongsToRow
						alignItems
						layout
						column
						items {
							key
							title
							description
							link {
								url
							}
							image {
								localFile {
									childImageSharp {
										fluid(srcSetBreakpoints: [800, 250], quality: 80) {
											...GatsbyImageSharpFluid_noBase64
										}
									}
								}
							}
						}
					}
					... on WordPressAcf_special {
						belongsToRow
						alignItems
						layout
						column
						element
					}
					... on WordPressAcf_video {
						belongsToRow
						alignItems
						layout
						column
						caption
						video_embed
					}
					... on WordPressAcf_accordion {
						belongsToRow
						layout
						column
						stil
						items {
							title
							content {
								content_elements {
									text
									as
									acf_fc_layout
									content
									theme
									appearance
									alignment
									caption
									button_theme
									link {
										title
										url
										target
									}
									optional_link {
										url
									}
									items {
										key
										title
										text
									}
									max_width
									image {
										source_url
										alt_text
										title
										localFile {
											extension
											childImageSharp {
												id
												fluid(srcSetBreakpoints: [800, 250], quality: 80) {
													...GatsbyImageSharpFluid_noBase64
												}
											}
										}
									}
								}
							}
						}
					}
					... on WordPressAcf_releasenumbers {
						belongsToRow
						alignItems
						layout
						column
						content
					}
					... on WordPressAcf_sidebubble {
						belongsToRow
						alignItems
						layout
						column
						title {
							source_url
							alt_text
							title
							localFile {
								extension
								childImageSharp {
									id
									fluid(srcSetBreakpoints: [800, 250], quality: 80) {
										...GatsbyImageSharpFluid_noBase64
									}
								}
							}
						}
						sidebubble_titel
						text
						button_text
						button {
							url
						}
						lazy_time
						weekdays
					}
					... on WordPressAcf_cookiespage {
						belongsToRow
						layout
						column
						render
					}
					... on WordPressAcf_tab_anchor_link {
						belongsToRow
						layout
						column
						tab_name
						anchor_text
						page_link {
							title
							url
							target
						}
						anchor_id
					}
					... on WordPressAcf_slider {
						belongsToRow
						layout
						column
						image_1 {
							source_url
							alt_text
							title
							localFile {
								extension
								childImageSharp {
									id
									fluid(srcSetBreakpoints: [800, 250], quality: 80) {
										...GatsbyImageSharpFluid_noBase64
									}
								}
							}
						}
						image_2 {
							source_url
							alt_text
							title
							localFile {
								extension
								childImageSharp {
									id
									fluid(srcSetBreakpoints: [800, 250], quality: 80) {
										...GatsbyImageSharpFluid_noBase64
									}
								}
							}
						}
						image_3 {
							source_url
							alt_text
							title
							localFile {
								extension
								childImageSharp {
									id
									fluid(srcSetBreakpoints: [800, 250], quality: 80) {
										...GatsbyImageSharpFluid_noBase64
									}
								}
							}
						}
						image_4 {
							source_url
							alt_text
							title
							localFile {
								extension
								childImageSharp {
									id
									fluid(srcSetBreakpoints: [800, 250], quality: 80) {
										...GatsbyImageSharpFluid_noBase64
									}
								}
							}
						}
						image_5 {
							source_url
							alt_text
							title
							localFile {
								extension
								childImageSharp {
									id
									fluid(srcSetBreakpoints: [800, 250], quality: 80) {
										...GatsbyImageSharpFluid_noBase64
									}
								}
							}
						}
						video_embed_1
						video_embed_2
						video_embed_3
						video_embed_4
						video_embed_5
					}
					... on WordPressAcf_dynamicslider {
						belongsToRow
						alignItems
						layout
						column
						videos {
							video
							caption
						}
						images {
							image {
								source_url
								alt_text
								title
								localFile {
									extension
									childImageSharp {
										id
										fluid(srcSetBreakpoints: [800, 250], quality: 80) {
											...GatsbyImageSharpFluid_noBase64
										}
									}
								}
							}
						}
					}
					... on WordPressAcf_icontextlist {
						belongsToRow
						alignItems
						layout
						column
						icontextlist {
							text
							icon {
								source_url
								alt_text
								title
								localFile {
									extension
									childImageSharp {
										id
										fluid(srcSetBreakpoints: [800, 250], quality: 80) {
											...GatsbyImageSharpFluid_noBase64
										}
									}
								}
							}
						}
					}
					... on WordPressAcf_tabs {
						belongsToRow
						alignItems
						layout
						column
						tabs {
							tabname
							tabid
							content_elements {
								acf_fc_layout
								text
								as
								content
								theme
								appearance
								alignment
								caption
								button_theme
								anchor_id
								icontextlist {
									text
									icon {
										source_url
										alt_text
										title
										localFile {
											extension
											childImageSharp {
												id
												fluid(srcSetBreakpoints: [800, 250], quality: 80) {
													...GatsbyImageSharpFluid_noBase64
												}
											}
										}
									}
								}
								file {
									title
									filename
									url {
										localFile {
											publicURL
										}
									}
								}
								link {
									title
									url
									target
								}
								optional_link {
									url
								}
								items {
									key
									title
									text
								}
								max_width
								image {
									source_url
									alt_text
									title
									localFile {
										extension
										childImageSharp {
											id
											fluid(srcSetBreakpoints: [800, 250], quality: 80) {
												...GatsbyImageSharpFluid_noBase64
											}
										}
									}
								}
								video_embed
								videos {
									video
									caption
								}
								images {
									image {
										source_url
										alt_text
										title
										localFile {
											extension
											childImageSharp {
												id
												fluid(srcSetBreakpoints: [800, 250], quality: 80) {
													...GatsbyImageSharpFluid_noBase64
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
`;

import React, {Component} from 'react';
import GlossaryMock from './GlossaryMock';
import GlossaryBlock from './GlossaryBlock';
import {
	GlossaryContainer,
	GlossaryAZ,
	GlossaryAZItem,
	GlossaryItemContainer,
	GlossaryItem,
	GlossaryItemLetter
} from './styles';

class Glossary extends Component {
	state = {
		activeBlock: ['a']
	};

	getFormattedData = () => {
		const mockdata = [];
		Object.keys(GlossaryMock).forEach(key => {
			const char = key.charAt(0).toLowerCase();
			if (!mockdata[char]) {
				mockdata[char] = [];
			}
			mockdata[char].push([key, GlossaryMock[key]]);
		});

		return mockdata;
	};

	handleAzClick = el => {
		const {letter} = el.target.dataset;
		this.setState({activeBlock: [letter]});
	};

	render() {
		const mockData = this.getFormattedData();
		const {activeBlock} = this.state;

		return (
			<GlossaryContainer>
				<GlossaryAZ>
					{Object.keys(mockData).map(key => (
						<GlossaryAZItem
							key={`glossary-az-item-${key}`}
							onClick={this.handleAzClick}
							active={activeBlock.includes(key)}
							data-letter={key}>
							<span>{key}</span>
						</GlossaryAZItem>
					))}
				</GlossaryAZ>
				<GlossaryItemContainer>
					{Object.keys(mockData).map(key => (
						<GlossaryItem
							data-trigger={key}
							key={`glossary-item-${key}`}
							active={activeBlock.includes(key)}>
							<GlossaryItemLetter>{key.toUpperCase()}</GlossaryItemLetter>
							<GlossaryBlock items={mockData[key]} />
						</GlossaryItem>
					))}
				</GlossaryItemContainer>
			</GlossaryContainer>
		);
	}
}

export default Glossary;

import React from 'react';
import Logo from '../../../assets/img/360grad.png';
import {ServiceLogoStyled} from './styles';
import {Link} from 'gatsby';

const LogoService = () => {
	return (
		<Link to='/service'>
			<ServiceLogoStyled src={Logo} />
		</Link>
	);
};
/** @component */
export default LogoService;

import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Button from '../Elements/Button/Button';
import {unsetPZN} from '../../state/actions/userManagement';
import {media} from '../../styles/utils';

const ResetButton = styled(Button)`
	
	
	justify-content: center;
	font-size: 1em;
	margin-top: 1em;
	margin-bottom: 1em;
	margin-left: 0;
	margin-right: 1em; 

	${media.md`
    font-size: 0.8em;
	margin-top: 0;
	margin-bottom: 2em;	
	`};

	${media.xl`
    font-size: 1em;
  `}
`;

const ResetPZNButton = ({handleReset}) => {
	return <ResetButton text="MS-Behandlung wechseln" onClick={handleReset} />;
};

ResetPZNButton.propTypes = {
	handleReset: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => ({
	handleReset: () => dispatch(unsetPZN())
});

export default connect(
	null,
	mapDispatchToProps
)(ResetPZNButton);

import {useState, useEffect} from 'react';

export function usePrevPath(location) {
	const [initialPrevPath, setInitialPrevPath] = useState(null);

	useEffect(() => {
		const prevPath = location?.state?.prevUrlPath;
		if (!initialPrevPath) {
			setInitialPrevPath(prevPath);
		}
	}, []);

	return [initialPrevPath];
}

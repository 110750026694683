import styled, {css} from 'styled-components';
import {colors, sizes} from '../../../styles/variables';
import {withUnit, gradientCandyCarrot, media} from '../../../styles/utils';

export const GlossaryContainer = styled.div`
	border: 0;
`;

export const GlossaryAZ = styled.div`
	border: 0;
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	${media.xl`
		justify-content: space-between;
	`};
	margin-bottom: ${withUnit(sizes.distance.base * 1.5)};
`;

const GlossaryAZItemActiveStyles = css`
	${gradientCandyCarrot()};
	color: ${colors.white};
`;

export const GlossaryAZItem = styled.div`
	cursor: pointer;
	position: relative;

	display: flex;
	align-items: center;
	justify-content: center;
	width: 12vw;
	height: 12vw;

	${media.md`
		width: 8vw;
		height: 8vw;
	`};

	&::before {
		content: '';
		display: block;
		padding-bottom: 100%;
	}

	span {
		pointer-events: none;
		display: flex;
		align-items: center;
		justify-content: center;

		text-transform: uppercase;
		font-weight: 600;

		width: 90%;
		height: 90%;
		max-width: ${withUnit(sizes.distance.base * 2)};
		max-height: ${withUnit(sizes.distance.base * 2)};
		border-radius: 50%;
		background-color: ${colors.snow};
		transform: translateY(0);

		${props => (props.active ? GlossaryAZItemActiveStyles : '')};

		will-change: transform, box-shadow, background-color;
		transition: transform 0.3s ease-out, box-shadow 0.3s ease-out, background-color 0.3s ease-out,
			background-position 0.3s ease-out;
	}

	&:not([disabled]):hover span {
		transform: translateY(-3px);
		box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.15);
	}
`;

export const GlossaryItemContainer = styled.div`
	display: block;
	border-bottom: 4px solid ${colors.carrot};

	padding: 0 0 ${withUnit(sizes.distance.base * 1.5)} 0;
`;

const GlossaryItemActiveStyles = css`
	display: block;
`;

export const GlossaryItem = styled.div`
	display: none;

	${props => (props.active ? GlossaryItemActiveStyles : ``)};
`;

export const GlossaryItemItems = styled.div`
	display: block;
	border-bottom: 2px solid ${colors.carrot};
	padding: ${withUnit(sizes.distance.base)} 0;
	${media.md`
		padding: ${withUnit(sizes.distance.base)} ${withUnit(sizes.distance.base / 2)};
	`};

	&:first-child {
		padding-top: 0;
	}

	&:last-child {
		padding-bottom: 0;
		border-bottom: 0;
	}
`;

export const GlossaryItemLetter = styled.div`
	font-weight: bold;
	padding-bottom: ${withUnit(sizes.distance.base)};
	border-bottom: 4px solid ${colors.carrot};
`;

export const GlossaryItemHeadline = styled.div`
	font-weight: bold;
`;

export const GlossaryItemText = styled.div`
	display: block;
`;

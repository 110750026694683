/* eslint-disable no-throw-literal */
const GET = 'GET';

const checkStatus = response => {
	if (response.status >= 200 && response.status < 300) {
		return response;
	}
	const error = new Error(`HTTP Error ${response.statusText}`);
	error.status = response.statusText;
	error.response = response;
	throw error;
};

const parseJson = response => {
	// re-raise an new error, based on a string (which is expected in <ErrorPanel>)
	const errorHandler = e => {
		throw `"${e.message}", trat auf während des Parsens des Suchergebnisses.`;
	};
	return response.json().catch(errorHandler);
};

const fetchWordpressContent = (id, type) => {
	const payload = {
		method: GET,
		headers: {
			'Content-Type': 'application/json'
		}
	};
	return fetch(`${process.env.GATSBY_WORDPRESS_API_URL}/wp/v2/${type}/${id}`, payload)
		.then(checkStatus)
		.then(parseJson);
};

const typeMapper = {
	acf_fc_layout: '__typename',
	text: 'text',
	content: 'content',
	element: 'element',
	as: 'as',
	items: 'items',
	infobox: 'infobox',
	alignment: 'alignment',
	appearance: 'appearance',
	headline: 'headline',
	video: 'video',
	caption: 'caption',
	image: 'image',
	list: 'list',
	max_width: 'max_width',
	link: 'link'
};

const transformWPData = (typeMapped, payload) => {
	const res = {};
	let newPayload = payload;
	if (typeMapped === '__typename') {
		newPayload = `WordPressAcf_${payload}`;
	}
	res[typeMapped] = newPayload;
	return res;
};

const buildWpPage = sectionColumn => {
	const componentsPage = [];
	if (sectionColumn.content_elements === null) {
		return [];
	}
	for (let y = 0; y < sectionColumn.content_elements.length; y += 1) {
		const contentElement = sectionColumn.content_elements[y];
		const transformedObject = {};
		const keys = Object.keys(contentElement);
		for (let i = 0; i < keys.length; i += 1) {
			const key = keys[i];
			const typeMapped = typeMapper[key];
			if (typeMapped) {
				const result = transformWPData(typeMapped, contentElement[key]);
				const key1 = Object.keys(result)[0];
				transformedObject[key1] = result[key1];
			}
		}
		componentsPage.push(transformedObject);
	}
	return componentsPage;
};

export const buildComponents = (id, staticPage, type) => {
	return fetchWordpressContent(id, type).then(res => {
		let componentsPage = [];
		for (let i = 0; i < res.acf.section.length; i += 1) {
			const section = res.acf.section[i];
			if (section.column_1) {
				const resultArray = buildWpPage(section.column_1);
				componentsPage = [...componentsPage, ...resultArray];
			}
			if (section.column_2) {
				const resultArray = buildWpPage(section.column_2);
				componentsPage = [...componentsPage, ...resultArray];
			}
		}
		// hydrate dynamically fetched content into existing page object
		for (let i = 0; i < staticPage.components.length; i += 1) {
			if (staticPage.components[i].text && componentsPage[i].text !== 'undefined') {
				staticPage.components[i].text = componentsPage[i].text;
			}
			if (staticPage.components[i].content && componentsPage[i].content !== 'undefined') {
				staticPage.components[i].content = componentsPage[i].content;
			}
			if (
				staticPage.components[i].items &&
				componentsPage[i].items !== 'undefined' &&
				staticPage.components[i].__typename !== 'WordPressAcf_teasergroup'
			) {
				staticPage.components[i].items = componentsPage[i].items;
			}
			if (staticPage.components[i].caption && componentsPage[i].caption !== 'undefined') {
				staticPage.components[i].caption = componentsPage[i].caption;
			}
		}
		return staticPage;
	});
};
